import { ModalBody, ModalFooter } from '@common/PromiseModal';
import { Button, ButtonVariant } from '@kit/ui/Button';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  CheckboxGroupField,
  DateTimeField,
  Form,
  FormValidationRules,
  InputField,
  RadioGroupField,
  SelectField,
  useForm
} from '@kit/components/Form';
import { useDeepCompareEffect } from '@react-hookz/web';
import { isSameOrAfterDate, isSameOrBeforeDate } from '@utils/dates';
import { useDebouncedMemo } from '@hooks/useDebouncedMemo';
import { DashboardListItem, LayoutItem, WidgetConfig } from '@hooks/analytics/useDashboardList';
import { useUpdateDashboard } from '@hooks/analytics/useUpdateDashboard';
import { ProjectStatus } from '@generated/types/graphql';
import {
  AnalyticsModule,
  AnalyticsSubmeasureType,
  AnalyticsWidgetAggregateFunction,
  AnalyticsWidgetType,
  DimensionType,
  MeasureOption,
  PredefinedMeasureId,
  StandardPropertyId,
  PositiveTrendType,
  AnalyticsWidgetDateRangeType,
  WidgetSettings as TWidgetSettings,
  WidgetFilters,
  ShowFilterType
} from '../types';
import {
  ConfigAndPreview,
  DateRangeFilterRow,
  DoubleField,
  FieldWrapper,
  FiltersTitle,
  Preview,
  Separator,
  TwoFields,
  WidgetTypeRadioGroupWrapper
} from './styled';
import {
  AGGREGATE_FUNCTION_COUNT_OPTION,
  AGGREGATE_FUNCTION_OPTIONS,
  MEASURES_WITHOUT_SUBMEASURE,
  MODULE_OPTIONS,
  POSITIVE_TREND_TYPE_OPTIONS,
  PROJECT_STATUS_RADIO_OPTIONS,
  SUBMEASURE_TYPE_OPTIONS,
  WIDGET_TYPES_WITH_TREND,
  WIDGET_TYPE_OPTIONS,
  WORKFLOW_FIELD_LABEL_BY_WIDGET_TYPE
} from '../constants';
import { WidgetTypeOption } from './WidgetTypeOption';
import {
  getSubmeasureOptionGroupBy,
  useSubmeasureOptions,
  getMeasureOptionGroupBy,
  useMeasureOptions,
  useDimensionOptions,
  getSubdimensionGroupBy,
  isSubdimensionRequired,
  useSubdimensionOptions,
  isWorkflowRequired,
  useWorkflowOptions,
  useDateRangeOptions,
  useShowFilterOptions
} from '../hooks';
import { DEFAULT_DATE_RANGE_OPTION, DateRangeOption } from '../dateRangeConfig';
import { isSettingsValid, isStatusLifecycleWorkflow } from '../helpers';
import { Widget } from '../Widget';
import { Filters } from '../Filters';
import { DataPreview } from './DataPreview';

type FormValues = {
  title: string;
  subtitle: string;
  module: AnalyticsModule;
  widgetType: AnalyticsWidgetType;
  aggregateFunction: { id: AnalyticsWidgetAggregateFunction; name: string };
  measure: MeasureOption;
  submeasureType: { id: AnalyticsSubmeasureType; name: string };
  submeasure: { id: string; name: string };
  dimension: { id: DimensionType; name: string };
  subdimension: { id: DimensionType; name: string };
  positiveTrendType: PositiveTrendType;
  workflow: { id: number; name: string };
  dateRangeType: DateRangeOption;
  dateRangeStart: Date;
  dateRangeEnd: Date;
  show: ShowFilterType;
  includedTimelineStatuses: ProjectStatus[];
};

const mapFormValuesToWidgetSettings = (values: FormValues, filters?: WidgetFilters): TWidgetSettings => {
  return {
    title: values.title,
    subtitle: values.subtitle,
    module: values.module,
    widgetType: values.widgetType,
    aggregateFunctionId: values.aggregateFunction?.id,
    measure: values.measure?.property,
    submeasureTypeId: values.submeasureType?.id,
    submeasureId: values.submeasure?.id,
    dimensionId: values.dimension?.id,
    subdimensionId: values.subdimension?.id,
    positiveTrendType: values.positiveTrendType,
    workflowId: values.workflow?.id,
    dateRangeType: values.dateRangeType?.id,
    dateRangeStart: values.dateRangeStart?.toISOString(),
    dateRangeEnd: values.dateRangeEnd?.toISOString(),
    show: values.show,
    filters,
    includedTimelineStatuses: values.includedTimelineStatuses ?? []
  };
};

const mapWidgetConfigToFormValues = (widgetConfig: WidgetConfig) => {
  // this only initialize part of form, next step is to fill the rest of the form
  return {
    title: widgetConfig.title,
    subtitle: widgetConfig.subtitle,
    module: widgetConfig.module as AnalyticsModule,
    widgetType: widgetConfig.widgetType as AnalyticsWidgetType,
    show: (widgetConfig.show as ShowFilterType) || ShowFilterType.ACTIVE,
    aggregateFunction: AGGREGATE_FUNCTION_OPTIONS.find((option) => option.id === widgetConfig.aggregateFunctionId),
    positiveTrendType: widgetConfig.positiveTrendType as PositiveTrendType
  };
};
interface Props {
  initialValues?: LayoutItem;
  dashboard: DashboardListItem;
  onClose: () => void;
}

type FiltersRef = {
  getFilters: () => WidgetFilters;
  resetFilters: () => void;
};

export const WidgetSettings = ({ initialValues, dashboard, onClose }: Props) => {
  const isNew = !initialValues;

  const [filters, setFilters] = useState<WidgetFilters | undefined>();

  const { mutateAsync } = useUpdateDashboard();

  const filtersRef = useRef<FiltersRef>(null);

  const postForm = async (values: FormValues) => {
    const config = mapFormValuesToWidgetSettings(values, filters);

    if (isNew) {
      const nextIndex = dashboard.layout.length ? Math.max(...dashboard.layout.map(({ i }) => +i)) + 1 : 1;

      await mutateAsync({
        id: dashboard.id,
        dto: {
          layout: [
            ...dashboard.layout,
            {
              x: 0,
              y: dashboard.layout.length,
              w: 4,
              h: 2,
              i: nextIndex.toString(),
              widgetConfig: config
            }
          ]
        }
      });
    } else {
      const updatedLayout = dashboard.layout.map((item) => {
        if (item.i === initialValues.i) {
          return {
            ...item,
            widgetConfig: config
          };
        }

        return item;
      });

      await mutateAsync({
        id: dashboard.id,
        dto: {
          layout: updatedLayout
        }
      });
    }

    onClose();
  };

  const isInitedRef = useRef(!!isNew);

  const { form, handleSubmit } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: isNew
      ? {
          module: AnalyticsModule.CLIENTS,
          widgetType: AnalyticsWidgetType.KPI,
          aggregateFunction: AGGREGATE_FUNCTION_COUNT_OPTION,
          positiveTrendType: POSITIVE_TREND_TYPE_OPTIONS[0].value,
          dateRangeType: DEFAULT_DATE_RANGE_OPTION,
          show: ShowFilterType.ACTIVE
        }
      : mapWidgetConfigToFormValues(initialValues.widgetConfig)
  });

  const { control, watch, getValues, clearErrors, reset, setValue } = form;

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      title: {
        isRequired: true
      },
      module: {
        isRequired: true
      },
      widgetType: {
        isRequired: true
      },
      aggregateFunction: {
        isRequired: true
      },
      measure: {
        isRequired: true
      },
      submeasureType: {
        validate: (value) => {
          const formValues = getValues();

          if (formValues.submeasure && !value) {
            return 'This is required';
          }

          return undefined;
        }
      },
      dimension: {
        isRequired: true,
        validate: (value) => {
          const formValues = getValues();

          if (formValues.widgetType !== AnalyticsWidgetType.KPI && !value) {
            return 'Dimension is required';
          }

          return undefined;
        }
      },
      subdimension: {
        isRequired: true
      },
      positiveTrendType: {
        isRequired: true
      },
      workflow: {
        isRequired: true
      },
      dateRangeType: {
        isRequired: true
      },
      dateRangeStart: {
        isRequired: true
      },
      dateRangeEnd: {
        isRequired: true
      },
      show: {
        isRequired: true
      },
      includedTimelineStatuses: {
        isRequired: true
      }
    }),
    [getValues]
  );

  const values = watch();

  const { module, widgetType, measure, submeasure, dimension, dateRangeType, dateRangeStart, dateRangeEnd, workflow } =
    values;

  const measureOptions = useMeasureOptions(module);
  const submeasureOptions = useSubmeasureOptions(module);
  const dimensionOptions = useDimensionOptions(module, widgetType);
  const subdimensionOptions = useSubdimensionOptions(module, dimension?.id);
  const workflowOptions = useWorkflowOptions(module, widgetType);
  const dateRangeOptions = useDateRangeOptions();
  const showOptions = useShowFilterOptions(module);

  useEffect(() => {
    if (isInitedRef.current) {
      return;
    }

    if (!initialValues) {
      isInitedRef.current = true;

      return;
    }

    // wait for all options to be loaded
    if (
      measureOptions.length === 0 ||
      submeasureOptions.length === 0 ||
      (isWorkflowRequired(widgetType) && workflowOptions.length === 0)
    ) {
      return;
    }

    const { widgetConfig } = initialValues;

    const dateRangeType = dateRangeOptions.find((option) => option.id === widgetConfig.dateRangeType);

    reset((prev) => ({
      ...prev,
      aggregateFunction:
        widgetConfig.aggregateFunctionId === AnalyticsWidgetAggregateFunction.COUNT
          ? AGGREGATE_FUNCTION_COUNT_OPTION
          : AGGREGATE_FUNCTION_OPTIONS.find((option) => option.id === widgetConfig.aggregateFunctionId),
      measure: widgetConfig.measure
        ? measureOptions.find(
            (option) =>
              option.property.id === widgetConfig.measure.id &&
              option.property.entityType === widgetConfig.measure.entityType
          )
        : null,
      submeasureType: widgetConfig.submeasureTypeId
        ? SUBMEASURE_TYPE_OPTIONS.find((option) => option.id === widgetConfig.submeasureTypeId)
        : null,
      submeasure: widgetConfig.submeasureId
        ? submeasureOptions.find((option) => option.id === widgetConfig.submeasureId)
        : null,
      dimension: widgetConfig.dimensionId
        ? dimensionOptions.find((option) => option.id === widgetConfig.dimensionId)
        : null,
      subdimension: widgetConfig.subdimensionId
        ? subdimensionOptions.find((option) => option.id === widgetConfig.subdimensionId)
        : null,
      workflow: widgetConfig.workflowId
        ? workflowOptions.find((option) => option.id === widgetConfig.workflowId)
        : null,
      dateRangeType: dateRangeOptions.find((option) => option.id === widgetConfig.dateRangeType),
      dateRangeStart: (() => {
        if (dateRangeType.startDate) {
          return dateRangeType.startDate().toJSDate();
        }

        return widgetConfig.dateRangeStart ? new Date(widgetConfig.dateRangeStart) : undefined;
      })(),
      dateRangeEnd: (() => {
        if (dateRangeType.endDate) {
          return dateRangeType.endDate().toJSDate();
        }

        return widgetConfig.dateRangeEnd ? new Date(widgetConfig.dateRangeEnd) : undefined;
      })(),
      includedTimelineStatuses: widgetConfig.includedTimelineStatuses ?? []
    }));

    setTimeout(() => {
      isInitedRef.current = true;
    }, 0);
  }, [
    initialValues,
    widgetType,
    reset,
    measureOptions,
    submeasureOptions,
    dimensionOptions,
    subdimensionOptions,
    workflowOptions,
    dateRangeOptions
  ]);

  useEffect(() => {
    if (!isInitedRef.current) {
      return;
    }

    clearErrors('submeasureType');
  }, [submeasure, clearErrors]);

  useEffect(() => {
    if (!isInitedRef.current) {
      return;
    }

    setValue('measure', measureOptions[0]);
  }, [setValue, measureOptions]);

  useDeepCompareEffect(() => {
    if (!isInitedRef.current) {
      return;
    }

    reset((prev) => {
      const hasSameDimension = dimensionOptions.some((option) => option.value === prev.dimension?.value);
      const shouldHaveSubdimension = isSubdimensionRequired(prev.dimension?.id);

      const subdimension = hasSameDimension ? prev.subdimension : subdimensionOptions[0];

      return {
        ...prev,
        show: ShowFilterType.ACTIVE,
        measure: measureOptions[0],
        positiveTrendType: POSITIVE_TREND_TYPE_OPTIONS[0].value,
        aggregateFunction: AGGREGATE_FUNCTION_COUNT_OPTION,
        submeasure: null,
        submeasureType: null,
        workflow: null,
        dimension: hasSameDimension ? prev.dimension : dimensionOptions[0],
        subdimension: shouldHaveSubdimension ? subdimension : null
      };
    });

    filtersRef.current?.resetFilters();
  }, [module, reset, measureOptions, dimensionOptions]);

  useDeepCompareEffect(() => {
    if (!isInitedRef.current) {
      return;
    }

    reset((prev) => {
      if (
        prev.aggregateFunction?.id !== AGGREGATE_FUNCTION_COUNT_OPTION.id &&
        measure?.property.id === StandardPropertyId.ID
      ) {
        return {
          ...prev,
          aggregateFunction: AGGREGATE_FUNCTION_COUNT_OPTION
        };
      } else if (
        prev.aggregateFunction?.id === AGGREGATE_FUNCTION_COUNT_OPTION.id &&
        measure?.property.id !== StandardPropertyId.ID
      ) {
        return {
          ...prev,
          aggregateFunction: AGGREGATE_FUNCTION_OPTIONS[0]
        };
      }

      return prev;
    });
  }, [measure, reset]);

  useDeepCompareEffect(() => {
    if (!isInitedRef.current) {
      return;
    }

    reset((prev) => ({
      ...prev,
      workflow: workflowOptions.length === 1 ? workflowOptions[0] : null,
      dimension: dimensionOptions.some((option) => option.id === prev.dimension?.id)
        ? prev.dimension
        : dimensionOptions[0]
    }));
  }, [widgetType, reset, dimensionOptions, workflowOptions]);

  useDeepCompareEffect(() => {
    if (!isInitedRef.current) {
      return;
    }

    if (measure?.value === PredefinedMeasureId.TIME_TO_COMPLETE) {
      reset((prev) => ({
        ...prev,
        positiveTrendType: PositiveTrendType.DOWN
      }));
    }
  }, [measure, reset]);

  useDeepCompareEffect(() => {
    if (!isInitedRef.current) {
      return;
    }

    reset((prev) => ({
      ...prev,
      subdimension: subdimensionOptions.some((option) => option.id === prev.subdimension?.id)
        ? prev.subdimension
        : subdimensionOptions[0]
    }));
  }, [dimension, reset, subdimensionOptions]);

  useEffect(() => {
    if (!isInitedRef.current) {
      return;
    }

    if (dateRangeType?.startDate) {
      setValue('dateRangeStart', dateRangeType.startDate().toJSDate());
    }
    if (dateRangeType?.endDate) {
      setValue('dateRangeEnd', dateRangeType.endDate().toJSDate());
    }
  }, [dateRangeType, setValue]);

  useDeepCompareEffect(() => {
    if (widgetType === AnalyticsWidgetType.TIMELINE && workflow && module === AnalyticsModule.PROJECTS) {
      reset((prev) => ({
        ...prev,
        includedTimelineStatuses:
          prev.includedTimelineStatuses?.length > 0 ? prev.includedTimelineStatuses : [ProjectStatus.Active]
      }));
    }
  }, [widgetType, workflow, module]);

  const handleStartDateRangeDateChangedManually = useCallback(
    (_value: Date, isChanged: boolean) => {
      if (
        isChanged &&
        dateRangeType?.id !== AnalyticsWidgetDateRangeType.CUSTOM &&
        dateRangeType?.id !== AnalyticsWidgetDateRangeType.CUSTOM_TO_DATE
      ) {
        setValue('dateRangeType', { id: AnalyticsWidgetDateRangeType.CUSTOM, name: 'Custom' });
      }
    },
    [setValue, dateRangeType]
  );

  const handleEndDateRangeDateChangedManually = useCallback(
    (_value: Date, isChanged: boolean) => {
      if (isChanged && dateRangeType?.id !== AnalyticsWidgetDateRangeType.CUSTOM) {
        setValue('dateRangeType', { id: AnalyticsWidgetDateRangeType.CUSTOM, name: 'Custom' });
      }
    },
    [setValue, dateRangeType]
  );

  const isOnlyWorkflow = isWorkflowRequired(widgetType);

  const widgetSettings = useDebouncedMemo(
    () => ({
      i: '0',
      x: 0,
      y: 0,
      w: 4,
      h: 4,
      widgetConfig: mapFormValuesToWidgetSettings(values, filters)
    }),
    [values, filters],
    300
  );

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <ModalBody width="1420px">
        <ConfigAndPreview>
          <div>
            <InputField name="title" label="Title" control={control} />
            <InputField name="subtitle" label="Subtitle" control={control} />
            <RadioGroupField layout="row" name="module" label="Module" options={MODULE_OPTIONS} control={control} />
            <WidgetTypeRadioGroupWrapper>
              <RadioGroupField
                layout="row"
                name="widgetType"
                label="Widget type"
                options={WIDGET_TYPE_OPTIONS}
                control={control}
                renderOptionLabel={(option, isActive) => <WidgetTypeOption option={option} isActive={isActive} />}
              />
            </WidgetTypeRadioGroupWrapper>

            {isOnlyWorkflow && (
              <>
                <SelectField
                  disabled={workflowOptions.length === 1}
                  label={WORKFLOW_FIELD_LABEL_BY_WIDGET_TYPE[widgetType]}
                  name="workflow"
                  options={workflowOptions}
                  control={control}
                />

                {module === AnalyticsModule.PROJECTS &&
                  widgetType === AnalyticsWidgetType.TIMELINE &&
                  workflow &&
                  !isStatusLifecycleWorkflow(workflow.id) && (
                    <CheckboxGroupField
                      layout="row"
                      label="Include time spent in"
                      name="includedTimelineStatuses"
                      options={PROJECT_STATUS_RADIO_OPTIONS}
                      control={control}
                    />
                  )}
              </>
            )}

            {!isOnlyWorkflow && (
              <>
                <TwoFields>
                  <DoubleField rightInputWidth="80px">
                    <SelectField
                      groupBy={getMeasureOptionGroupBy}
                      label="Measure"
                      name="measure"
                      options={measureOptions}
                      control={control}
                      clearOnUnmount={false}
                    />
                    <SelectField
                      isClearable={false}
                      name="aggregateFunction"
                      label="Measure"
                      options={
                        measure?.property.id === StandardPropertyId.ID
                          ? [AGGREGATE_FUNCTION_COUNT_OPTION]
                          : AGGREGATE_FUNCTION_OPTIONS
                      }
                      control={control}
                    />
                  </DoubleField>

                  {!measure || !MEASURES_WITHOUT_SUBMEASURE.includes(measure.value) ? (
                    <DoubleField leftInputWidth="122px">
                      <FieldWrapper isLabelHidden>
                        <SelectField
                          isClearable={!submeasure}
                          placeholder={submeasure ? 'Select' : 'Optional'}
                          name="submeasureType"
                          label={module === AnalyticsModule.PROJECTS ? 'Stage (optional)' : 'Status (optional)'}
                          options={SUBMEASURE_TYPE_OPTIONS}
                          control={control}
                        />
                      </FieldWrapper>
                      <FieldWrapper isLabelHidden>
                        <SelectField
                          placeholder="Optional"
                          label="Submeasure"
                          name="submeasure"
                          options={submeasureOptions}
                          groupBy={getSubmeasureOptionGroupBy}
                          control={control}
                        />
                      </FieldWrapper>
                    </DoubleField>
                  ) : (
                    <div />
                  )}
                </TwoFields>

                {dimensionOptions.length > 0 && (
                  <TwoFields>
                    <SelectField label="Group by" name="dimension" options={dimensionOptions} control={control} />
                    {isSubdimensionRequired(dimension?.id) ? (
                      <FieldWrapper isLabelHidden={dimension?.id !== DimensionType.TIME}>
                        <SelectField
                          label="Granularity"
                          name="subdimension"
                          groupBy={getSubdimensionGroupBy}
                          options={subdimensionOptions}
                          control={control}
                        />
                      </FieldWrapper>
                    ) : (
                      <div />
                    )}
                  </TwoFields>
                )}

                {WIDGET_TYPES_WITH_TREND.includes(widgetType) &&
                  measure?.value !== PredefinedMeasureId.TIME_TO_COMPLETE && (
                    <RadioGroupField
                      layout="row"
                      name="positiveTrendType"
                      label="Trend"
                      options={POSITIVE_TREND_TYPE_OPTIONS}
                      control={control}
                      clearOnUnmount={false}
                    />
                  )}
              </>
            )}

            <Separator />

            <div>
              <FiltersTitle>Filters</FiltersTitle>

              <DateRangeFilterRow>
                <SelectField label="Date range" name="dateRangeType" options={dateRangeOptions} control={control} />
                {dateRangeType && dateRangeType.id !== AnalyticsWidgetDateRangeType.ALL_TIME ? (
                  <div>
                    <DateTimeField
                      onClose={handleStartDateRangeDateChangedManually}
                      placeholder="Start date"
                      isOnlyDate
                      fixHoursToNoon={false}
                      name="dateRangeStart"
                      control={control}
                      filterDate={(date) => isSameOrBeforeDate(date, dateRangeEnd)}
                    />
                    <div>to</div>
                    <DateTimeField
                      onClose={handleEndDateRangeDateChangedManually}
                      placeholder="End date"
                      isOnlyDate
                      fixHoursToNoon={false}
                      name="dateRangeEnd"
                      control={control}
                      filterDate={(date) => isSameOrAfterDate(date, dateRangeStart)}
                    />
                  </div>
                ) : (
                  <div />
                )}
              </DateRangeFilterRow>

              <RadioGroupField layout="row" name="show" label="Show" options={showOptions} control={control} />

              <Filters
                initialFilters={initialValues?.widgetConfig.filters}
                ref={filtersRef}
                module={module}
                onChange={setFilters}
              />
            </div>
          </div>
          <Preview>
            {isSettingsValid(widgetSettings.widgetConfig) && <Widget layoutItem={widgetSettings} />}
            {!isSettingsValid(widgetSettings.widgetConfig) && <div>Fill all required fields to see the preview</div>}
          </Preview>
        </ConfigAndPreview>
        <Separator />

        <DataPreview settings={widgetSettings.widgetConfig} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>
        <Button type="submit" variant={ButtonVariant.Primary}>
          {isNew ? 'Create' : 'Update'}
        </Button>
      </ModalFooter>
    </Form>
  );
};
